var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"footer-props":_vm.dataTableFooter,"no-data-text":"ไม่มีข้อมูลในช่วงเวลานี้","loading-text":"กรุณารอสักครู่..."},scopedSlots:_vm._u([(_vm.isNational)?{key:"item.subTotal",fn:function(ref){
var value = ref.value;
return [_vm._v(" $"+_vm._s(_vm._f("showFullPriceFormat")(value))+" ")]}}:null,{key:"item.thbTotal",fn:function(ref){
var value = ref.value;
return [_vm._v(" ฿"+_vm._s(_vm._f("showFullPriceFormat")(value))+" ")]}},(_vm.isNational)?{key:"item.unitDiscount",fn:function(ref){
var value = ref.value;
return [_vm._v(" $"+_vm._s(_vm._f("showFullPriceFormat")(value))+" ")]}}:null,{key:"item.shipCost",fn:function(ref){
var value = ref.value;
return [_vm._v(" $"+_vm._s(_vm._f("showFullPriceFormat")(value))+" ")]}},{key:"item.sgNet",fn:function(ref){
var value = ref.value;
return [_vm._v(" $"+_vm._s(_vm._f("showFullPriceFormat")(value))+" ")]}},{key:"item.thbNet",fn:function(ref){
var value = ref.value;
return [_vm._v(" ฿"+_vm._s(_vm._f("showFullPriceFormat")(value))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }