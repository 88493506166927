<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    :footer-props="dataTableFooter"
    no-data-text="ไม่มีข้อมูลในช่วงเวลานี้"
    loading-text="กรุณารอสักครู่...">
    <template
      v-if="isNational"
      v-slot:[`item.subTotal`]="{ value }">
      ${{ value | showFullPriceFormat() }}
    </template>
    <template v-slot:[`item.thbTotal`]="{ value }">
      ฿{{ value | showFullPriceFormat() }}
    </template>
    <template
      v-if="isNational"
      v-slot:[`item.unitDiscount`]="{ value }">
      ${{ value | showFullPriceFormat() }}
    </template>
    <template v-slot:[`item.shipCost`]="{ value }">
      ${{ value | showFullPriceFormat() }}
    </template>
    <template v-slot:[`item.sgNet`]="{ value }">
      ${{ value | showFullPriceFormat() }}
    </template>
    <template v-slot:[`item.thbNet`]="{ value }">
      ฿{{ value | showFullPriceFormat() }}
    </template>
  </v-data-table>
</template>

<script>
  export default {
    props: {
      headers: {
        type: Array,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      isNational: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dataTableFooter: {
          itemsPerPageText: 'จำนวนแสดงต่อหน้า',
          itemsPerPageOptions: [5, 10, 15],
          showFirstLastPage: true
        }
      }
    }
  }
</script>
